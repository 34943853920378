import { isRouteErrorResponse } from '@remix-run/react'
import { Badge } from '@repo/ui/components/Badge.js'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '@repo/ui/components/Card.js'
import { Separator } from '@repo/ui/components/Separator.js'
import { Illustrations } from './illustrations'

export const ErrorCard = ({ error }: { error: unknown }) => {
  return (
    <Card className="w-full max-w-xl shadow-sm">
      <CardHeader className="space-y-5">
        <div className="w-full bg-secondary/50 p-4 rounded-lg border flex items-center justify-center">
          <div className="w-60">
            <Illustrations.serverDown />
          </div>
        </div>
        <CardTitle className="text-xl">Hmm, something's not right.</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 text-sm">
        <Separator />
        {isRouteErrorResponse(error) ? (
          <>
            <div className="flex items-center gap-4">
              <span className="font-medium w-20">Status: </span>
              <Badge variant="secondary" size="sm">
                {error.status}
              </Badge>
            </div>

            <div className="flex items-center gap-4">
              <span className="font-medium w-20">Message: </span>
              <pre>{error.statusText}</pre>
            </div>
          </>
        ) : error instanceof Error ? (
          <div className="flex items-center gap-4">
            <pre className="whitespace-pre-wrap">{error.message}</pre>
          </div>
        ) : (
          <div>
            We're not quit sure what happened, but we've been alerted of the
            error and are looking into it.
          </div>
        )}
      </CardContent>
    </Card>
  )
}
